import { Menu, useResourceDefinitions } from "react-admin";
import ParkIcon from "@mui/icons-material/Park";

export const CustomMenu = () => {
  const resources = useResourceDefinitions();

  return (
    <Menu>
      {Object.keys(resources).map((name) => (
        <Menu.ResourceItem key={name} name={name} />
      ))}
      <Menu.Item
        to="/cables/tree"
        primaryText="Дерево вариантов"
        leftIcon={<ParkIcon />}
      />
    </Menu>
  );
};
