import { FieldGuesser, ListGuesser, ShowGuesser } from "@api-platform/admin";
import {
  ImageField,
  ReferenceField,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import { JsonField } from "react-admin-json-view";

const CableList = (props: any) => {
  return (
    <ListGuesser {...props}>
      <FieldGuesser source={"name"} />
      <ReferenceField source="twist" reference="cable_twists">
        <TextField source="title"></TextField>
      </ReferenceField>
      <ReferenceField source="twistsNumber" reference="cable_twists_numbers">
        <TextField source="number"></TextField>
      </ReferenceField>
      <ReferenceField source="coreSection" reference="cable_core_sections">
        <TextField source="area" />
      </ReferenceField>
      <ReferenceField source="flexibility" reference="cable_flexibilities">
        <TextField source="title"></TextField>
      </ReferenceField>
      <ReferenceField
        source="fireHazardClass"
        reference="cable_fire_hazard_classes"
      >
        <TextField source="title"></TextField>
      </ReferenceField>
      <ReferenceField source="coreTinning" reference="cable_core_tinnings">
        <TextField source="title"></TextField>
      </ReferenceField>
      <ReferenceField source="pe" reference="cable_p_es">
        <TextField source="title"></TextField>
      </ReferenceField>
      <ReferenceField source="twistShield" reference="cable_shields">
        <TextField source="title"></TextField>
      </ReferenceField>
      <ReferenceField source="twistInsulation" reference="cable_insulations">
        <TextField source="title"></TextField>
      </ReferenceField>
      <ReferenceField source="generalShield" reference="cable_shields">
        <TextField source="title"></TextField>
      </ReferenceField>
      <ReferenceField source="armor" reference="cable_armors">
        <TextField source="title"></TextField>
      </ReferenceField>
      <ReferenceField
        source="climaticVersion"
        reference="cable_climatic_versions"
      >
        <TextField source="title"></TextField>
      </ReferenceField>
      <ReferenceField source="uvResist" reference="cable_uv_resists">
        <TextField source="title"></TextField>
      </ReferenceField>
      <ReferenceField
        source="chemicalResist"
        reference="cable_chemical_resists"
      >
        <TextField source="title"></TextField>
      </ReferenceField>
      <ReferenceField
        source="rodentsDefence"
        reference="cable_rodents_defences"
      >
        <TextField source="title"></TextField>
      </ReferenceField>
      <FieldGuesser source={"constructionLength"} />
      <FieldGuesser source={"mountingTemp"} />
      <FieldGuesser source={"operatingTemp"} />
      <FieldGuesser source={"bendingRadius"} />
      <FieldGuesser source={"serviceTime"} />
      <ImageField source={"imageUrl"} />
    </ListGuesser>
  );
};

const CableTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>{record.name}</span>;
};

const CableShow = (props: any) => {
  return (
    <ShowGuesser {...props} title={<CableTitle />}>
      <TabbedShowLayout>
        <Tab label="Параметры">
          <ReferenceField source="flexibility" reference="cable_flexibilities">
            <TextField source="description" />
          </ReferenceField>
          <ReferenceField
            source="fireHazardClass"
            reference="cable_fire_hazard_classes"
          >
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField
            source="twistsNumber"
            reference="cable_twists_numbers"
          >
            <TextField source="number" />
          </ReferenceField>
          <ReferenceField source="twist" reference="cable_twists">
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField source="coreSection" reference="cable_core_sections">
            <TextField source="area" />
          </ReferenceField>
          <ReferenceField source="coreTinning" reference="cable_core_tinnings">
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField source="pe" reference="cable_p_es">
            <TextField source="desctitleription" />
          </ReferenceField>
          <ReferenceField source="twistShield" reference="cable_shields">
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField source="generalShield" reference="cable_shields">
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField
            source="twistInsulation"
            reference="cable_insulations"
          >
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField source="armor" reference="cable_armors">
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField
            source="climaticVersion"
            reference="cable_climatic_versions"
          >
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField source="uvResist" reference="cable_uv_resists">
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField
            source="chemicalResist"
            reference="cable_chemical_resists"
          >
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField
            source="rodentsDefence"
            reference="cable_rodents_defences"
          >
            <TextField source="title" />
          </ReferenceField>
        </Tab>
        <Tab label="Тексты">
          <FieldGuesser source={"description"} />
          <FieldGuesser source={"purpose"} />
          <FieldGuesser source={"application"} />
          <FieldGuesser source={"constructionLength"} />
          <FieldGuesser source={"mountingTemp"} />
          <FieldGuesser source={"operatingTemp"} />
          <FieldGuesser source={"bendingRadius"} />
          <FieldGuesser source={"serviceTime"} />
        </Tab>
        <Tab label="Прочее">
          <FieldGuesser source={"previewUrl"} />
        </Tab>
        <Tab label="3D">
          <JsonField
            source={"modelVars"}
            reactJsonOptions={{
              name: null,
              displayDataTypes: false,
              displayObjectSize: false,
              enableClipboard: false,
            }}
          />
        </Tab>
      </TabbedShowLayout>
    </ShowGuesser>
  );
};

export { CableList, CableShow };
