const russianMessages = require("ra-language-russian");

const dictionaryBase = {
  name: "Наименование для маркоразмера",
  title: "Заголовок",
  description: "Описание",
  comparisonKey: "Ключ сравнения",
  empty: "Пустое значение",
  flexibility: "Гибкость",
  fireHazardClass: "Класс пожароопасности",
  twistsNumber: "Количество скруток",
  twist: "Тип скрутки",
  coreSection: "Сечение",
  coreTinning: "Лужение",
  pe: "Жила PE",
  twistShield: "Экран скрутки",
  twistInsulation: "Материал изоляции",
  generalShield: "Экран",
  armor: "Броня",
  climaticVersion: "Климатическое исполнение",
  uvResist: "Защита УФ",
  chemicalResist: "Химическая стойкость",
  rodentsDefence: "Защита от грызунов",
  constructionLength: "Строительная длина",
  mountingTemp: "t монтажных изгибов",
  operatingTemp: "Температура эксплуатации",
  bendingRadius: "Радиус изгиба",
  serviceTime: "Срок эксплуатации",
  coreCount: "Число жил",
  twistsNumbers: "Варианты скруток",
  coreSections: "Варианты сечений",
  number: "Число",
  area: "Площадь сечения",
  fireHazardClasses: "Классы пожароопасности",
  insulated: "С изоляцией",
  insulatedVersion: "Версия с изоляцией",
  twists: "Тип скруток",
  insulations: "Материал изоляции",
  textGenVars: "Параметры генератора текстов",
  sortIndex: "Порядок сортировки",
  modelVars: "Параметры 3D",
};

const messages = {
  ra: {
    ...russianMessages.ra,
    configurable: {
      customize: "XXXXXXXX",
    },
  },
  resources: {
    cables: {
      name: "Маркоразмер |||| Маркоразмеры",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_armors: {
      name: "Броня",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_twists: {
      name: "Тип скрутки |||| Типы скруток",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_twists_numbers: {
      name: "Число скруток |||| Числа скруток",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_core_sections: {
      name: "Тип сечения |||| Типы сечений",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_fire_hazard_classes: {
      name: "Класс пожароопасности |||| Классы пожароопасности",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_chemical_resists: {
      name: "Тип химической стойкости |||| Типы химической стойкости",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_climatic_versions: {
      name: "Тип климатического исполнения |||| Типы климатического исполнения",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_core_tinnings: {
      name: "Тип лужения жил |||| Типы лужения жил",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_flexibilities: {
      name: "Тип гибкости |||| Типы гибкости",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_insulations: {
      name: "Материал изоляции |||| Материалы изоляции",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_p_es: {
      name: "Тип жилы PE |||| Типы жилы PE",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_rodents_defences: {
      name: "Тип ЗГ |||| Типы ЗГ",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_uv_resists: {
      name: "Тип стойкости УФ  |||| Типы стойкости УФ",
      fields: {
        ...dictionaryBase,
      },
    },
    cable_shields: {
      name: "Экран |||| Экраны",
      fields: {
        ...dictionaryBase,
      },
    },
    settings: {
      name: "Настройка |||| Настройки",
      fields: {
        title: "Заголовок",
        name: "Имя",
        value: "Значение",
      },
    },
  },
};

export default messages;
