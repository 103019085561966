import {
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser,
} from "@api-platform/admin";

import { JsonInput } from "react-admin-json-view";
import TextInputStyle from "../style/TextInputStyle";

const CableRodentsDefenceList = (props: any) => (
  <ListGuesser {...props}>
    <FieldGuesser source="title" />
    <FieldGuesser source="name" />
    <FieldGuesser source="sortIndex" />
  </ListGuesser>
);

const CableRodentsDefenceEdit = (props: any) => (
  <EditGuesser {...props}>
    <InputGuesser style={TextInputStyle} source={"name"} />
    <InputGuesser style={TextInputStyle} source={"title"} />
    <InputGuesser style={TextInputStyle} source={"description"} />
    <InputGuesser style={TextInputStyle} source={"sortIndex"} />

    <JsonInput
      source={"textGenVars"}
      reactJsonOptions={{
        name: null,
        displayDataTypes: false,
        displayObjectSize: false,
        enableClipboard: false,
        onAdd: () => {},
      }}
    />
    <JsonInput
      source={"modelVars"}
      reactJsonOptions={{
        name: null,
        displayDataTypes: false,
        displayObjectSize: false,
        enableClipboard: false,
        defaultValue: "",
        onAdd: () => {},
      }}
    />
  </EditGuesser>
);

export { CableRodentsDefenceList, CableRodentsDefenceEdit };
