import {
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser,
} from "@api-platform/admin";
import { ReferenceArrayInput, SelectArrayInput } from "react-admin";
import { JsonInput } from "react-admin-json-view";
import TextInputStyle from "../style/TextInputStyle";

const CableClimaticVersionList = (props: any) => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"} />
    <FieldGuesser source={"description"} />
    <FieldGuesser source={"title"} />
    <FieldGuesser source={"sortIndex"} />
  </ListGuesser>
);

const CableClimaticVersionEdit = (props: any) => (
  <EditGuesser {...props}>
    <InputGuesser style={TextInputStyle} source={"title"} />
    <InputGuesser style={TextInputStyle} source={"name"} />
    <InputGuesser style={TextInputStyle} source={"description"} />
    <InputGuesser style={TextInputStyle} source={"sortIndex"} />

    <ReferenceArrayInput source="insulations" reference="cable_insulations">
      <SelectArrayInput optionText="title" />
    </ReferenceArrayInput>

    <JsonInput
      source={"textGenVars"}
      reactJsonOptions={{
        name: null,
        displayDataTypes: false,
        displayObjectSize: false,
        enableClipboard: false,
        onAdd: () => {},
      }}
    />
    <JsonInput
      source={"modelVars"}
      reactJsonOptions={{
        name: null,
        displayDataTypes: false,
        displayObjectSize: false,
        enableClipboard: false,
        defaultValue: "",
        onAdd: () => {},
      }}
    />
  </EditGuesser>
);

export { CableClimaticVersionList, CableClimaticVersionEdit };
