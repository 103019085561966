import { useState } from "react";
import { Navigate, Route } from "react-router-dom";
import { CustomRoutes } from "react-admin";
import {
  fetchHydra as baseFetchHydra,
  HydraAdmin,
  hydraDataProvider as baseHydraDataProvider,
  ResourceGuesser,
  useIntrospection,
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import authProvider from "./utils/authProvider";
import { ENTRYPOINT } from "./config";
import {
  CableCoreSectionEdit,
  CableCoreSectionList,
} from "./compontents/CableCoreSection";
import { CableTwistEdit, CableTwistList } from "./compontents/CableTwist";
import {
  CableInsulationEdit,
  CableInsulationList,
} from "./compontents/CableInsulation";
import { CableShieldEdit, CableShieldList } from "./compontents/CableShield";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { CableList, CableShow } from "./compontents/Cable";
import messages from "./translation/messages";
import CableIcon from "@mui/icons-material/Cable";
import ShieldIcon from "@mui/icons-material/Shield";
import StraightenIcon from "@mui/icons-material/Straighten";
import FireplaceIcon from "@mui/icons-material/Fireplace";
import ScienceIcon from "@mui/icons-material/Science";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import AlbumIcon from "@mui/icons-material/Album";
import PestControlRodentIcon from "@mui/icons-material/PestControlRodent";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";
import CameraIcon from "@mui/icons-material/Camera";
import SettingsIcon from "@mui/icons-material/Settings";
import CableTree from "./compontents/CableTree";
import { CustomLayout } from "./admin/Layout";
import {
  CableClimaticVersionEdit,
  CableClimaticVersionList,
} from "./compontents/CableClimaticVersion";
import {
  CableFireHazardClassEdit,
  CableFireHazardClassList,
} from "./compontents/CableFireHazardClass";
import { CableArmorEdit, CableArmorList } from "./compontents/CableArmor";
import {
  CableTwistsNumberEdit,
  CableTwistsNumberList,
} from "./compontents/CableTwistsNumber";
import {
  CableCoreTinningEdit,
  CableCoreTinningList,
} from "./compontents/CableCoreTinning";
import { CablePeEdit, CablePeList } from "./compontents/CablePe";
import {
  CableUvResistEdit,
  CableUvResistList,
} from "./compontents/CableUvResist";
import {
  CableFlexibilityEdit,
  CableFlexibilityList,
} from "./compontents/CableFlexibility";
import {
  CableRodentsDefenceEdit,
  CableRodentsDefenceList,
} from "./compontents/CableRodentsDefence";
import { SettingEdit, SettingList } from "./compontents/Setting";
import {
  CableChemicalResistEdit,
  CableChemicalResistList,
} from "./compontents/CableChemicalResist";

const getHeaders = () => {
  if (localStorage.getItem("auth")) {
    const auth = JSON.parse(localStorage.getItem("auth") as string);
    return {
      Authorization: `Bearer ${auth.token}`,
    } as Record<string, string>;
  }

  return {};
};

const fetchHydra = (url: URL, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });
const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem("auth")) {
    introspect();
    return <></>;
  }
  return <Navigate to="/login" />;
};
const apiDocumentationParser =
  (setRedirectToLogin: CallableFunction) => async () => {
    try {
      setRedirectToLogin(false);

      return await parseHydraDocumentation(ENTRYPOINT, { headers: getHeaders });
    } catch (result: any) {
      const { api, response, status } = result;
      if (status !== 401 || !response) {
        throw result;
      }

      // Prevent infinite loop if the token is expired
      localStorage.removeItem("auth");

      setRedirectToLogin(true);

      return {
        api,
        response,
        status,
      };
    }
  };
const dataProvider = (setRedirectToLogin: CallableFunction) =>
  baseHydraDataProvider({
    entrypoint: ENTRYPOINT,
    httpClient: fetchHydra,
    apiDocumentationParser: apiDocumentationParser(setRedirectToLogin),
  });

const i18nProvider = polyglotI18nProvider(() => messages, "ru");

const Admin = () => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  return (
    <>
      <HydraAdmin
        dataProvider={dataProvider(setRedirectToLogin)}
        authProvider={authProvider}
        entrypoint={ENTRYPOINT}
        i18nProvider={i18nProvider}
        layout={CustomLayout}
      >
        <ResourceGuesser
          name="cables"
          icon={CableIcon}
          list={CableList}
          show={CableShow}
          recordRepresentation={(record) => record.name}
        />
        <ResourceGuesser
          name="cable_armors"
          icon={CameraIcon}
          list={CableArmorList}
          edit={CableArmorEdit}
          recordRepresentation="asdkfkalsfd"
        />
        <ResourceGuesser
          name="cable_twists"
          icon={WorkspacesIcon}
          list={CableTwistList}
          edit={CableTwistEdit}
          recordRepresentation="xui"
        />
        <ResourceGuesser
          name="cable_twists_numbers"
          icon={FormatListNumberedIcon}
          list={CableTwistsNumberList}
          edit={CableTwistsNumberEdit}
          recordRepresentation={(record) => record.number}
        />
        <ResourceGuesser
          name="cable_core_sections"
          icon={StraightenIcon}
          list={CableCoreSectionList}
          edit={CableCoreSectionEdit}
          recordRepresentation={(record) => record.title}
        />
        <ResourceGuesser
          name="cable_fire_hazard_classes"
          icon={FireplaceIcon}
          list={CableFireHazardClassList}
          edit={CableFireHazardClassEdit}
          recordRepresentation={(record) => record.title}
        />
        <ResourceGuesser
          name="cable_chemical_resists"
          icon={ScienceIcon}
          list={CableChemicalResistList}
          edit={CableChemicalResistEdit}
          recordRepresentation={(record) => record.title}
        />
        <ResourceGuesser
          name="cable_climatic_versions"
          icon={DeviceThermostatIcon}
          list={CableClimaticVersionList}
          edit={CableClimaticVersionEdit}
          recordRepresentation={(record) => record.title}
        />
        <ResourceGuesser
          name="cable_core_tinnings"
          icon={FormatColorResetIcon}
          list={CableCoreTinningList}
          edit={CableCoreTinningEdit}
          recordRepresentation={(record) => record.title}
        />
        <ResourceGuesser
          name="cable_flexibilities"
          list={CableFlexibilityList}
          edit={CableFlexibilityEdit}
          recordRepresentation={(record) => record.title}
        />
        <ResourceGuesser
          name="cable_insulations"
          icon={AlbumIcon}
          list={CableInsulationList}
          edit={CableInsulationEdit}
          recordRepresentation={(record) => record.title}
        />
        <ResourceGuesser
          name="cable_p_es"
          icon={ElectricBoltIcon}
          list={CablePeList}
          edit={CablePeEdit}
          recordRepresentation={(record) => record.title}
        />
        <ResourceGuesser
          name="cable_rodents_defences"
          icon={PestControlRodentIcon}
          list={CableRodentsDefenceList}
          edit={CableRodentsDefenceEdit}
          recordRepresentation={(record) => record.title}
        />
        <ResourceGuesser
          name="cable_uv_resists"
          icon={WbSunnyIcon}
          list={CableUvResistList}
          edit={CableUvResistEdit}
          recordRepresentation={(record) => record.title}
        />
        <ResourceGuesser
          name="cable_shields"
          icon={ShieldIcon}
          recordRepresentation={(record) => record.title}
          list={CableShieldList}
          edit={CableShieldEdit}
        />
        <ResourceGuesser
          name="settings"
          icon={SettingsIcon}
          list={SettingList}
          edit={SettingEdit}
        />
        <CustomRoutes>
          {redirectToLogin ? (
            <Route path="/" element={<RedirectToLogin />} />
          ) : null}
          <Route
            path="/cables/tree"
            element={<CableTree headers={getHeaders} entryPoint={ENTRYPOINT} />}
          />
        </CustomRoutes>
      </HydraAdmin>
    </>
  );
};
export default Admin;
