import {
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser,
} from "@api-platform/admin";
import { TextInput } from "react-admin";

import TextInputStyle from "../style/TextInputStyle";

const SettingList = (props: any) => (
  <ListGuesser {...props}>
    <FieldGuesser source="title" />
    <FieldGuesser source="name" />
  </ListGuesser>
);

const SettingEdit = (props: any) => (
  <EditGuesser {...props}>
    <InputGuesser style={TextInputStyle} source={"title"} />
    <InputGuesser style={TextInputStyle} source={"name"} />
    <TextInput style={TextInputStyle} multiline={true} source={"value"} />
  </EditGuesser>
);

export { SettingList, SettingEdit };
