import { AuthProvider } from "react-admin";
import { AUTH_ENTRYPOINT } from "../config";

const authProvider: AuthProvider = {
  // send username and password to the auth server and get back credentials
  login: (params) => {
    const request = new Request(AUTH_ENTRYPOINT, {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem("auth", JSON.stringify(auth));
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  // when the dataProvider returns an error, check if this is an authentication error
  checkError: (error) => {
    const status = error.status;

    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject({
        redirectTo: "/login",
        message: "Unauthorized user",
      });
    }
    return Promise.resolve();
  },
  // when the user navigates, make sure that their credentials are still valid
  checkAuth: () =>
    localStorage.getItem("auth")
      ? Promise.resolve()
      : Promise.reject({ redirectTo: "/login" }),
  // remove local credentials and notify the auth server that the user logged out
  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  // get the user's profile
  getIdentity: (): Promise<any> => {
    try {
      console.log(JSON.parse(localStorage.getItem("auth") as string));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // get the user permissions (optional)
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
