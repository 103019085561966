import {
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser,
} from "@api-platform/admin";
import { ReferenceArrayInput, SelectArrayInput } from "react-admin";
import { JsonInput } from "react-admin-json-view";
import TextInputStyle from "../style/TextInputStyle";

const CableTwistList = (props: any) => (
  <ListGuesser {...props}>
    <FieldGuesser source={"title"} />
    <FieldGuesser source={"name"} />
    <FieldGuesser source={"coreCount"} />
    <FieldGuesser source={"sortIndex"} />
  </ListGuesser>
);

const CableTwistEdit = (props: any) => (
  <EditGuesser {...props}>
    <InputGuesser style={TextInputStyle} source={"name"} />
    <InputGuesser style={TextInputStyle} source={"title"} />
    <InputGuesser style={TextInputStyle} source={"description"} />
    <InputGuesser style={TextInputStyle} source={"coreCount"} />
    <InputGuesser style={TextInputStyle} source={"sortIndex"} />

    <ReferenceArrayInput
      source="twistsNumbers"
      reference="cable_twists_numbers"
    >
      <SelectArrayInput optionText="number" />
    </ReferenceArrayInput>
    <ReferenceArrayInput source="coreSections" reference="cable_core_sections">
      <SelectArrayInput optionText="area" />
    </ReferenceArrayInput>

    <JsonInput
      source={"textGenVars"}
      reactJsonOptions={{
        name: null,
        displayDataTypes: false,
        displayObjectSize: false,
        enableClipboard: false,
        onAdd: () => {},
        //onEdit: () => {},
      }}
    />
    <JsonInput
      source={"modelVars"}
      reactJsonOptions={{
        name: null,
        displayDataTypes: false,
        displayObjectSize: false,
        enableClipboard: false,
        defaultValue: "",
        onAdd: () => {},
      }}
    />
  </EditGuesser>
);

export { CableTwistList, CableTwistEdit };
