import {
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser,
} from "@api-platform/admin";

import { JsonInput } from "react-admin-json-view";
import TextInputStyle from "../style/TextInputStyle";

const CableArmorList = (props: any) => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"} />
    <FieldGuesser source={"description"} />
    <FieldGuesser source={"title"} />
    <FieldGuesser source={"empty"} />
    <FieldGuesser source={"sortIndex"} />
  </ListGuesser>
);

const CableArmorEdit = (props: any) => {
  return (
    <EditGuesser {...props}>
      <InputGuesser style={TextInputStyle} source={"name"} />
      <InputGuesser style={TextInputStyle} source={"title"} />
      <InputGuesser style={TextInputStyle} source={"description"} />
      <InputGuesser style={TextInputStyle} source={"sortIndex"} />
      <InputGuesser source={"empty"} />

      <JsonInput
        source={"textGenVars"}
        reactJsonOptions={{
          name: null,
          displayDataTypes: false,
          displayObjectSize: false,
          enableClipboard: false,
          defaultValue: "",
          onAdd: () => {},
        }}
      />
      <JsonInput
        source={"modelVars"}
        reactJsonOptions={{
          name: null,
          displayDataTypes: false,
          displayObjectSize: false,
          enableClipboard: false,
          defaultValue: "",
          onAdd: () => {},
        }}
      />
    </EditGuesser>
  );
};

export { CableArmorList, CableArmorEdit };
