import {
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser,
} from "@api-platform/admin";

import { JsonInput } from "react-admin-json-view";
import TextInputStyle from "../style/TextInputStyle";

const CableTwistsNumberList = (props: any) => (
  <ListGuesser {...props}>
    <FieldGuesser source={"number"} />
    <FieldGuesser source={"title"} />
  </ListGuesser>
);

const CableTwistsNumberEdit = (props: any) => (
  <EditGuesser {...props}>
    <InputGuesser style={TextInputStyle} source={"number"} />
    <InputGuesser style={TextInputStyle} source={"title"} />
    <InputGuesser style={TextInputStyle} source={"description"} />
    <InputGuesser style={TextInputStyle} source={"sortIndex"} />

    <JsonInput
      source={"textGenVars"}
      reactJsonOptions={{
        name: null,
        displayDataTypes: false,
        displayObjectSize: false,
        enableClipboard: false,
        onAdd: () => {},
      }}
    />
    <JsonInput
      source={"modelVars"}
      reactJsonOptions={{
        name: null,
        displayDataTypes: false,
        displayObjectSize: false,
        enableClipboard: false,
        defaultValue: "",
        onAdd: () => {},
      }}
    />
  </EditGuesser>
);

export { CableTwistsNumberList, CableTwistsNumberEdit };
