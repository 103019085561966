import {
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser,
} from "@api-platform/admin";
import {
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from "react-admin";
import { JsonInput } from "react-admin-json-view";
import TextInputStyle from "../style/TextInputStyle";

const CableShieldList = (props: any) => (
  <ListGuesser {...props}>
    <FieldGuesser source={"title"} />
    <FieldGuesser source={"name"} />
    <FieldGuesser source={"insulated"} />
    <ReferenceField source="insulatedVersion" reference="cable_shields">
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser source={"sortIndex"} />
  </ListGuesser>
);

const CableShieldEdit = (props: any) => (
  <EditGuesser {...props}>
    <InputGuesser style={TextInputStyle} source={"name"} />
    <InputGuesser style={TextInputStyle} source={"title"} />
    <InputGuesser style={TextInputStyle} source={"description"} />
    <InputGuesser style={TextInputStyle} source={"sortIndex"} />
    <InputGuesser style={TextInputStyle} source={"insulated"} />
    <ReferenceInput source="insulatedVersion" reference="cable_shields">
      <SelectInput optionText={"title"} />
    </ReferenceInput>

    <JsonInput
      source={"textGenVars"}
      reactJsonOptions={{
        name: null,
        displayDataTypes: false,
        displayObjectSize: false,
        enableClipboard: false,
        onAdd: () => {},
      }}
    />
    <JsonInput
      source={"modelVars"}
      reactJsonOptions={{
        name: null,
        displayDataTypes: false,
        displayObjectSize: false,
        enableClipboard: false,
        defaultValue: "",
        onAdd: () => {},
      }}
    />
  </EditGuesser>
);

export { CableShieldList, CableShieldEdit };
