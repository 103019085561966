import { useRef, useEffect, useState } from "react";
import { fetchUtils } from "react-admin";
import { useLocation } from "react-router-dom";
import * as d3 from "d3";

const CableTree = (props: { headers: () => {}; entryPoint: string }) => {
  const { headers, entryPoint } = props;
  const { search } = useLocation();

  const svgElementRef = useRef<SVGSVGElement | null>(null);
  const [data, setData] = useState<Object | null>(null);

  useEffect(() => {
    if (!entryPoint || !headers) {
      return;
    }

    fetchUtils
      .fetchJson(
        entryPoint + "/cables" + (search ? search + "&showTree" : "?showTree"),
        {
          headers: new Headers({
            ...headers(),
            Accept: "application/json",
            Preload: "/*/flexibility",
          }),
        }
      )
      .then((response) => setData(response.json));
  }, [headers, entryPoint, search]);

  useEffect(() => {
    if (!svgElementRef.current || !data) {
      return;
    }

    const root = d3.hierarchy<any>(data);

    const label = (d: any) => {
      const name = d.value ? d.value : "group";
      // const value = d.value ? "" + d.value.toString() + "" : "";
      // const mode =
      //   d.children && d.children.length > 0
      //     ? " - " + (d.valuesMode == 2 ? "all" : "active")
      //     : "";

      return name;
    };

    const padding = 3,
      width = 1400,
      descendants = root.descendants(),
      labels = descendants.map((d: any) => label(d.data));

    const dx = 20;
    const dy = width / (root.height + (padding != undefined ? padding : 0));
    d3.tree().nodeSize([dx, dy])(root);

    // Center the tree.
    let x0 = Infinity;
    let x1 = -x0;
    root.each((d: any) => {
      if (d.x > x1) x1 = d.x;
      if (d.x < x0) x0 = d.x;
    });

    const height = x1 - x0 + dx * 2;

    const svg = d3.select(svgElementRef.current);

    svg.selectAll("*").remove();
    svg
      .attr("viewBox", [(-dy * padding) / 2, x0 - dx, width, height])
      .attr("width", width)
      .attr("height", height)
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10);
    svg
      .append("g")
      .attr("stroke-opacity", 0.4)
      .attr("stroke-linecap", "miter")
      .attr("strokeLinejoin", "butt")
      .attr("fill", "none")
      .selectAll("path")
      .data(root.links())
      .join("path")
      .attr("stroke", (d: any, _x: any) => {
        if (d.source.data.valuesMode == 1) {
          return "#555";
        } else {
          return "blue";
        }
      })
      .attr(
        "d",
        d3
          .link<any, any>(d3.curveStep)
          .x((d: any) => d.y)
          .y((d: any) => d.x)
      );

    const node = svg
      .append("g")
      .selectAll("a")
      .data(root.descendants())
      .join("a")
      .attr("xlink:href", null)
      .attr("target", "_blank")
      .attr("transform", (d: any) => `translate(${d.y},${d.x})`);

    node
      .append("circle")
      .attr("fill", (d: any) => {
        return d.data.valuesMode == 1 ? "#555" : "blue";
      })
      .attr("r", 2);

    // Counts
    node
      .append("text")
      .attr("stroke", "#fff")
      .attr("x", -6)
      .attr("y", 15)
      .attr("text-anchor", "end")
      .attr("paint-order", "stroke")
      .attr("fill", "#000")
      .text((d: any) => {
        if (!d.children) {
          return null;
        }
        return Intl.NumberFormat("ru-RU", {
          style: "decimal",
        }).format(d.data.count);
      });

    node
      .append("text")
      .attr("stroke", "#fff")
      .attr("x", -6)
      .attr("y", -10)
      .attr("text-anchor", "end")
      .attr("font-size", "9")
      .attr("stroke-width", 3)
      .attr("paint-order", "stroke")
      .attr("fill", "#999")
      .text((d: any) => {
        if (!d.children) {
          return null;
        }
        return d.data.name;
      });

    node
      .append("text")
      .attr("dy", "0.32em")
      .attr("x", (d) => (d.children ? -6 : 6))
      .attr("text-anchor", (d) => (d.children ? "end" : "start"))
      .attr("paint-order", "stroke")
      .attr("stroke", "#fff")
      .attr("stroke-width", 3)
      .text((_d: any, i: number) => labels[i]);
  }, [svgElementRef, data, search]);

  return <svg ref={svgElementRef}></svg>;
};

export default CableTree;
